<template>
  <div class="content">
	  <!-- <el-scrollbar wrap-class="scrollbar-wrapper"> -->
	  <!-- :router="true" -->
	    <el-menu
	      :default-active="activeMenu"
	      :collapse="isCollapse"
	      background-color="#262D3C"
	      text-color="#fff"
	      :unique-opened="false"
	      :collapse-transition="true"
	      mode="vertical">
		  <p>{{routes.length}}</p>
			<template v-for="item in routes">
				<el-menu-item v-if="item.children.length==1" :key="item.menuId"  :index="resolvePath(item.path,item.children[0].path)" @click.native="linkProps(item.children[0],item.path)">
				    <i :class="item.meta.icon" style="color: #fff;"></i>
				    <span slot="title">{{item.children[0].meta.title}}</span>
				</el-menu-item>
				<el-submenu v-else ref="subMenu" :index="item.path" :key="item.menuId" popper-append-to-body>
				    <template slot="title" v-if="item.meta">
						<i :class="item.meta.icon"></i>
						<span>{{item.meta.title}}</span>
				    </template>
				    <el-menu-item v-for="child in item.children" :key="child.menuId" @click.native="linkProps(child,item.path)" :index="resolvePath(item.path,child.path)">{{child.meta.title}}</el-menu-item>
				</el-submenu>
			</template>
			
			<!-- <el-submenu ref="subMenu" v-for="item in routes" :key="item.path" :index="item.path" popper-append-to-body>
			    <template slot="title" v-if="item.meta">
					<i :class="item.meta.icon"></i>
					<span>{{item.meta.title}}</span>
			    </template>
			    <el-menu-item v-for="child in item.children" :key="child.path" @click.native="linkProps(child,item.path)" :index="resolvePath(item.path,child.path)">{{child.meta.title}}</el-menu-item>
			</el-submenu> -->
	    </el-menu>
	  <!-- </el-scrollbar> -->
  </div>
</template>

<script>
import path from 'path'

export default {
  name: 'MyMenus',
  props: {
    menuData: Object,
	defaultActive:{
		type: String,
		default: '1-1'
	},
	isCollapse:{
		type: Boolean,
		default: true
	}
  },
  computed:{
	  routes() {
		let routes=this.$router.options.routes;
		let arr=[];
		routes.forEach((val,key)=>{
			if(val.children){
				let i=-1;
				val.children.forEach((value,index)=>{
					if(value.visible===false){
						i=index
					}
				})
				if(i>-1){
					val.children.splice(i, 1);
				}
				arr.push(val)
			}
		})
		// console.log(arr);
	    return arr
	  },
	  activeMenu() {
		  // console.log(this.$route)
	    const route = this.$route
	    const { meta, path } = route
	    if (meta.activeMenu) {
	      return meta.activeMenu
	    }
	    return path
	  }
  },
  data(){
	  return{
		 role:1,
		 fullscreenLoading:false,    //全屏Loading
		 passwordDialogVisible:false, //修改密码 
		 // isCollapse:false,        //菜单是否收起
		 infoVisible:false,        //个人中心模态框是否显示
		 isFullScreen:false,         //是否全屏
		 active:'',
		 basePath:'',
		//  routes:[]
		 // defaultActive:'1-1',          //默认展开第几个菜单
	  }
  },
  mounted() {
	let _this = this
	// setTimeout(()=>{
	// 	localStorage.getItem('routes')?'':console.log('routes获取失败')
	// 	let routes = JSON.parse(localStorage.getItem('routes'))
	// 	let arr=[];
	// 	routes.forEach((val,key)=>{
	// 		if(val.children){
	// 			let i=-1;
	// 			val.children.forEach((value,index)=>{
	// 				if(value.visible===false){
	// 					i=index
	// 				}
	// 			})
	// 			if(i>-1){
	// 				val.children.splice(i, 1);
	// 			}
	// 			arr.push(val)
	// 		}
	// 	})
	// 	_this.routes = arr
	// 	_this.$forceUpdate()
	// },500)
  },
  methods:{
	  linkProps(child,parent){
		  // console.log(child,parent);
		 this.$router.push({
			path:parent+'/'+child.path,
			query: {
			    t: +new Date() //保证每次点击路由的query项都是不一样的，确保会重新刷新view 
			}
		 })
	  },
	  resolvePath(basePath,routePath) {
		  // console.log(basePath, routePath);
		  // console.log(path.resolve(basePath, routePath));
	    return path.resolve(basePath, routePath)
	  }
  },
  destroyed(){
		// EventBus.$off('abnormalDevice', {});
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.menu_leval1{
		display: flex;
		align-items: center;
	}
	.menu_leval1 div{
		width: 20px;
		margin-right: 10px;
	}
	.content{
		position: absolute;
		top:0.8rem;
		left:0;
		bottom:0;
		/* width: 200px; */
		display: flex;
		display: -webkit-flex;
		flex-flow: row wrap;
		z-index: 10;
	}
	.el-menu{
		border: 0;
		width: 200px;
	}
	.el-menu--collapse.el-menu{
		width: 64px;
	}
	/deep/ .el-scrollbar__wrap{
		overflow-x: hidden;
	}
	.content .el-icon-model_1{
	    width:20px !important;
	    height:20px;
	    /* background: url(../../public/images/ICon2.png) no-repeat; */
	    background-size: 100% 100%;
	}
	/deep/ .el-menu-item:hover,/deep/ .el-submenu__title:hover{
		background-color: rgb(255,255,255,0.2) !important;
	}
	/deep/.el-submenu__title i{
		color: #fff;
	}
	/deep/.el-submenu .el-menu-item {
	    height: 40px;
	    line-height: 40px;
	}
</style>
