import Vue from 'vue'
import router from '../router'

const refreshTokenKey = 'ALL_TOKEN'
//获取token 
var getToken=function(){
    return localStorage.getItem('AUTH_TOKEN');
}
//定时刷新token   
var intervalRefreshToken=function(){
	setInterval(function(){
		if(router.history.current.path!='/'){
			getTimeToken(refreshTokenKey,1000*60*60*2);
			// getTimeToken(refreshTokenKey,1000*60*0.1);
		}
	},1000*60*60);
}
//设置带时间的token  
var setTimeToken=function(value){
    var curTime = new Date().getTime();
    localStorage.setItem(refreshTokenKey,JSON.stringify({data:value,time:curTime}));
}
//获取带时间的token，并传入时间差exp  
var getTimeToken=function(key,exp){
    var data = localStorage.getItem(key);
	// console.log(data)
    var dataObj = JSON.parse(data);
    if (new Date().getTime() - dataObj.time>exp) {
        var refreshToken=dataObj.data;
		var json={
			refreshToken:refreshToken.refreshToken,
			accessToken:refreshToken.accessToken,
		};
		Vue.prototype.instance({
			method:'post',
			url:'/login/refreshToken',
			data:json,
			headers:{
				// 'Content-Type':"application/x-www-form-urlencoded",
			},
		}).then(res=>{
			var data=res.data;
			if(data.code==200){
				setTimeToken(data.data);
				// Vue.prototype.$axios.defaults.headers.common['Authorization'] =data.data.token;
				localStorage.setItem("AUTH_TOKEN",data.data.accessToken);
			}
		}).catch(function (error) {
			console.log(error);
		});
    }
}
export {getToken,intervalRefreshToken,setTimeToken,getTimeToken}